<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Link, router, useForm, usePage } from '@inertiajs/vue3';
import { ref, toRefs } from 'vue';

import {
    signOut,
    signInWithEmailAndPassword,
} from 'firebase/auth';
import { useFirebaseAuth } from 'vuefire';

import { locale } from '@/Localization.js';
import { trans, transChoice } from 'laravel-vue-i18n';

import uniqueId from "lodash.uniqueid";
const _uid = uniqueId('-login-form');

const props = defineProps({
    referer: { type: String },
    canResetPassword: { type: Boolean, },
    subscription: { type: Object },
});

const { subscription } = toRefs(props);

const formSocialConError = ref(null);
const formSocialErrors = {
    // Localization
    // 'auth/operation-not-allowed': `Opération non permise.`,
    'auth/popup-blocked': 'Vous devez autoriser les pop-ups.',
    // 'auth/popup-closed-by-user': ``,
    'auth/user-not-found': `Identifiant utilisateur non reconnu.`,
    // 'auth/network-request-failed': '',
    'auth/wrong-password': 'Couple email/mot de passe incorrect.',
    'EXPIRED': 'Votre compte existe mais votre abonnement est terminé.',
    'NOSUBSCRIPTION': 'Votre compte existe mais sans abonnement valide.',
};

const processing = ref(null);

const auth = useFirebaseAuth() // only exists on client side

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const handleSignOut = () => {
    signOut(auth).then(() => {
        var location = route('auth.login', { locale: locale.value });
        if (!route().current().startsWith('auth.')) {
            location = window.location.href;
        }
        router.visit(route('logout'), { method: 'post', data: { redirect: location } });
    });
};

const toggleSignIn = () => {

    formSocialConError.value = '';
    form.clearErrors();

    var email = form.email;
    var password = form.password;

    if (auth.currentUser) {
        handleSignOut();
    } else {

        processing.value = true;

        signInWithEmailAndPassword(auth, email, password)
            .then((result) => {
                processing.value = false;

                // var location = route('auth.profile');
                var location = route('customer.app.index', { 'locale': locale.value });
                if (route().current().startsWith('auth.')) {
                    window.location.href = location;
                } else {
                    // hydaroka@mailinator.com
                    // if (route().current() == 'partners.hoppen-hc.step' && !subscription.app_user_coupon) {
                    //     router.reload({
                    //         onFinish: visit => {
                    //             console.error('No redirect?', route().current(), auth.currentUser, usePage().props.auth.last_subscription_is);
                    //             if (usePage().props.auth.last_subscription_is == 'EXPIRED') {
                    //                 form.errors.email = formSocialErrors['EXPIRED'];
                    //             } else if (usePage().props.auth.last_subscription_is == 'NOSUBSCRIPTION') {
                    //                 form.errors.email = formSocialErrors['NOSUBSCRIPTION'];
                    //             }
                    //         },
                    //     });
                    // }
                }
            })
            .catch((error) => {
                processing.value = false;

                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode, errorMessage);

                if (errorCode == 'auth/wrong-password') {
                    form.errors.email = formSocialErrors[errorCode];
                } else {
                    if (typeof formSocialErrors[errorCode] != 'undefined') {
                        // formSocialConError.value = formSocialErrors[errorCode];
                        form.errors.email = formSocialErrors[errorCode];
                    }
                }
            });
    }
};

const switchPwdType = ref('password');
const changePwdType = () => {
    switchPwdType.value = switchPwdType.value == 'password' ? 'text' : 'password';
};

const showRegisterLink = route().current().startsWith('auth.');
</script>

<template>
    <form @submit.prevent="toggleSignIn" class="max-w-lg mx-auto">

        <!-- <pre>subscription: {{ subscription }}</pre>
        <pre>$page.props.auth: {{ $page.props.auth }}</pre> -->

        <div>
            <!--<InputLabel :for="`email-${_uid}`" value="Email" />-->
            <TextInput :id="`email-${_uid}`" type="email" class="mt-1 block w-full text-xl" v-model="form.email" required autocomplete="username" :placeholder="trans('Votre@email')" />
            <InputError class="mt-2" :message="form.errors.email" />
        </div>
        <div class="mt-6 mb-6">
            <!--<InputLabel :for="`password-${_uid}`" value="Password" />-->
            <div class="relative w-full">
                <div class="absolute inset-y-0 right-0 flex items-center px-2">
                    <label @click="changePwdType()" class="px-2 py-1 text-sm text-gray-600 dark:text-gray-300 hover:text-gray-800 font-mono cursor-pointer" :for="`password-${_uid}`">
                        <svg v-show="switchPwdType == 'password'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <svg v-show="switchPwdType != 'password'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                        </svg>
                        <!-- show -->
                    </label>
                </div>
                <TextInput :id="`password-${_uid}`" :type="switchPwdType" class="mt-1 block w-full text-xl" v-model="form.password" required autocomplete="current-password" :placeholder="trans('Votre mot de passe')" />
            </div>
            <InputError class="mt-2" :message="form.errors.password" />
        </div>

        <div class="mt-6">
            <label class="flex items-center justify-center gap-3">
                <Checkbox name="remember" v-model:checked="form.remember" />
                <span class="--text-sm text-gray-600 dark:text-gray-300 text-lg cursor-pointer">
                    {{ trans('Se souvenir de moi') }}
                </span>
            </label>
        </div>

        <InputError class="mt-2" :message="formSocialConError" />

        <div class="flex flex-col gap-4 items-center justify-center mt-6">
            <PrimaryButton :class="{ 'opacity-25': form.processing || processing /*|| auth.currentUser*/ }" :disabled="form.processing || processing /*|| auth.currentUser*/">
                {{ trans('Se connecter') }}
            </PrimaryButton>
            <div class="flex flex-col sm:flex-row gap-6 items-center justify-center mt-3">
                <Link v-if="canResetPassword" :href="route('auth.password.request')" class="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800">
                {{ trans('Mot de passe oublié ?') }}
                </Link>
                <Link v-if="showRegisterLink" :href="route('partners.default.index', { 'locale': locale, 'partner_slug': 'subscriptions' })" class="underline text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-offset-gray-800">
                {{ trans('Vous souhaitez vous inscrire ?') }}
                </Link>
            </div>
        </div>
    </form>
</template>